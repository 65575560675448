import React, { useState } from 'react'
import styled from 'styled-components'
import EventTile from './EventTile'

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;

  select {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 0;
    color: ${props => props.theme.text};

    option {
      margin: 40px;
      background-color: rgba(0, 0, 0, 0.8);
      color: ${props => props.theme.text};

      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: ${props => props.theme.text};
  }
`

// TODO:: Don't recreate each element on select change
// Attempt to store the created tiles in an object, and only
// reference them from there?
const EventsGrid = ({ events }) => {
  const [tiles, setTiles] = useState(events)
  const [selectValue, setSelectValue] = useState('all')

  const allActiveCategories = []
  events
    .filter(({ node: event }) => event.categories !== null)
    .map(({ node: event }) => event.categories)
    .map(categorySet => {
      return categorySet.map(cat => {
        allActiveCategories.push(cat)
      })
    })

  const distinctCategories = Array.from(
    new Set(allActiveCategories.map(cat => cat.slug))
  )
    .map(slug => {
      return {
        slug,
        name: allActiveCategories.find(cat => cat.slug === slug).name,
      }
    })
    .sort((a, b) => a.slug.localeCompare(b.slug))

  const onSelectChange = selectEvent => {
    const selectedValue = selectEvent.target.value
    setSelectValue(prevState => {
      if (prevState !== selectedValue) {
        let filteredEvents
        if (selectedValue !== 'all') {
          filteredEvents = events.filter(({ node: event }) => {
            return (
              event.categories !== null &&
              event.categories.some(cat => {
                return cat.slug === selectedValue
              })
            )
          })
        } else {
          filteredEvents = events
        }
        setTiles(filteredEvents)
        return selectedValue
      }
      return prevState
    })
  }

  return (
    <>
      <FilterWrapper className="filters">
        <div className="select">
          <select onChange={onSelectChange} value={selectValue}>
            <option key="all" value="all">
              All
            </option>
            {distinctCategories.map(distinctCat => {
              return (
                <option
                  key={distinctCat.slug}
                  value={distinctCat.slug}
                  dangerouslySetInnerHTML={{ __html: distinctCat.name }}
                />
              )
            })}
          </select>
        </div>
      </FilterWrapper>
      <div className="columns is-multiline is-gapless">
        {tiles.map(({ node: event }) => {
          const { featured_image } = event.acf
          const { slug } = event
          const { title } = event

          return (
            <EventTile
              slug={slug}
              title={title}
              featuredImage={featured_image}
              key={slug}
            />
          )
        })}
      </div>
    </>
  )
}

export default EventsGrid
