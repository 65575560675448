import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Tile = styled.article`
  display: flex !important;
  flex-direction: column;
  position: relative;

  picture {
    img {
      transition: all 2s ease-out !important;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  font-size: 0.7rem;
  font-weight: 100;
  justify-content: flex-end;
  padding: 0.7rem 1rem;
  background: rgba(0, 0, 0, 0.4);
`

const StyledLink = styled(props => <Link {...props} />)`
  position: relative;
  color: white;

  &:hover {
    color: rgba(255, 255, 255, 0.7) !important;
  }
`

const EventTile = ({ slug, title, featuredImage }) => {
  return (
    <Tile className="column is-one-third event-tile" key={title}>
      <StyledLink to={`/case-studies/${slug}`}>
        <Overlay dangerouslySetInnerHTML={{ __html: title }} />
        {featuredImage && featuredImage.localFile && (
          <Img fluid={featuredImage.localFile.childImageSharp.fluid} />
        )}
      </StyledLink>
    </Tile>
  )
}

export default EventTile
