import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import EventsGrid from '../components/Content/Events/EventsGrid'

const SectionWrapper = styled.section`
  padding: 3rem 0;
`

const SectionHeading = styled.h1`
  margin: 3rem 0 3rem;
  font-weight: 100;
`

const ArchiveTemplate = props => {
  const { data, pageContext } = props
  // const { title: siteTitle } = data.site.siteMetadata
  // const { name: category } = pageContext
  const eventNodes = data.allWordpressWpEvent.edges

  return (
    <Layout
      title={pageContext.title}
      themeOption={data.wordpressAcfOptions}
      categoryColors={data.allWordpressAcfCategories}
      yoast={false}
    >
      <SectionWrapper className="container-fluid is-marginless">
        <SectionHeading className="is-size-2 has-text-centered">
          CASE STUDIES
        </SectionHeading>

        <EventsGrid events={eventNodes} />
      </SectionWrapper>
    </Layout>
  )
}

export default ArchiveTemplate

export const pageQuery = graphql`
  query AllEventsQuery {
    allWordpressWpEvent {
      edges {
        node {
          title
          slug
          link
          status
          path
          wordpress_id
          type
          id
          excerpt
          date
          categories {
            name
            slug
          }
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 768, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        theme {
          name
          slug
          term_id
        }
      }
    }
    allWordpressAcfCategories {
      edges {
        node {
          acf {
            theme_color
            theme_text_color
          }
          wordpress_id
        }
      }
    }
  }
`
